import React, { useEffect, useState } from "react";
import "./NavBar.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import DropDownContent from "./DropDown/DropDown";
// import IndustriesData from "./IndustriesData";
// import ServicesData from "./ServicesData";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/naviso.png";
export default function NavBar2() {
  const [topExceed, settopExceed] = useState(false);
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    if (
      document.body.scrollTop > 60 ||
      document.documentElement.scrollTop > 60
    ) {
      settopExceed(true);
    } else {
      settopExceed(false);
    }
  }

  return (
    <div>
      <Navbar
        scrolling="true"
        expand="md"
        fixed="top"
        className={`navBar ${topExceed ? "changeColor" : ""}`}
      >
        <Navbar.Brand href="#home">
          <img alt="logo" src={Logo} className="navFig"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto navInside">
            <Link to="/">
              <span className="link">Home</span>
            </Link>
            <Link to="/about">
              <span className="link">About Us</span>
            </Link>

            {/* <NavDropdown alignRight title="Industries" id="basic-nav-dropdown">
              <DropDownContent
                data={IndustriesData}
                classto="dropDownMenu twoDiv"
              />
            </NavDropdown>
            <NavDropdown alignRight title="Services" id="basic-nav-dropdown">
              <DropDownContent
                data={ServicesData}
                classto="dropDownMenu threeDiv"
              />
            </NavDropdown> */}
            <Link to="/blog">
              <span className="link">Blog</span>
            </Link>
            <Link to="/careers">
              <span className="link">Careers</span>
            </Link>
            <Link to="/contact-us">
              <span className="link">Contact Us</span>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
