import React, { useEffect } from "react";
import ProManagementData from "./ProManagementData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function ProManagement({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="PRODUCT MANAGEMENT"
        description="Technology Solutions for Your Product"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/product-mangament.png"
        AccordData={ProManagementData}
      />
    </div>
  );
}

export default ProManagement;
