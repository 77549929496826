import React from "react";
import style from "./Details.module.css";
export default function Details() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <h2 className={style.getInTouch}>
          GET IN <strong>TOUCH</strong> WITH US
        </h2>
        <h6 className={style.genQuery}>General Query</h6>
        <p className={style.genEmail}>info@navishsol.com</p>
        <h6 className={style.careers}>Careers</h6>
        <p className={style.careersEmail}>hr@navishsol.com</p>

        <h2 className={style.globalP}>
          OUR <strong>GLOBAL</strong> PRESENCE
        </h2>

        <h6 className={style.contactHead}>Norway - Head Office</h6>
        <p className={style.contactDesc}>
          Beryllvegen 98,9022,<br></br>
          Oslo, Norway
        </p>
        <p className={style.contactPhone}>Phone: +47-93-923-306</p>

        <h6 className={style.contactHead}>Pakistan – Regional Office</h6>
        <p className={style.contactDesc}>
          Liberty Plaza, Gulberg,<br></br>
          Lahore, Pakistan
        </p>
        <p className={style.contactPhone}>Phone: +92-326-4427063</p>
      </div>
    </div>
  );
}
