import React from "react";
const webDevData = [
  {
    toggle: {
      title: "Our Web Development Perks",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "From frontend to backend to even full-stack development, we fast-track your project delivery to create an experience beyond your expectations. Our web development services comprise of tech leaders and developers who have significant experience in this niche. <strong>navishsol</strong> is a complete web development company and we warrant to generating most striking results. This way, we are not only improving your business but developing a relationship that will go a long way. This makes us, one of the finest in product designs, builds and overall web development."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Customized Web Development Solutions",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "With <strong>navishsol</strong>’s web development services, you can either choose to select from our designed templates, or you can create a unique idea of your own. While it is up to you how you imagine your web application or platform, let us take care of performance optimization, cloud system, and API deployment. We guarantee that by the time we are done with your web development project you will not only be given a perfect execution, but also swift delivery and handover."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Software That Grows With You",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "We, as a web development company, take into account your business growth rate when we work with you. It is our mission to provide our clients with agile solutions that continue to enhance performance even when their business is growing. Hence, with our web development services, we work to create an effective digital experience for our clients, their employees and their customers. <strong>navishsol</strong> develops applications using technologies that cater to your rapidly changing online business. Finally, our after-sale services make sure that you enjoy the best experience even after project delivery."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Security Is Guaranteed",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "Security towards development solutions might seem like an intractable task, but with the right organization procedures and practices, mitigations are possible. <strong>navishsol</strong> has always stressed the need that the web development services providers should follow best coding practices, scan applications for vulnerabilities and deploy virtual patching. The reality is that while the latest technology only pushes forward growth in one way it does the other way as well. Fortunately, we understand that web development is an iterative process that comprises of four main tasks: Design, Implementation, Testing for susceptibility and Monitoring. Through this process, we, at <strong>navishsol</strong>, concentrate on the daily growing risk of security and work to make your platform secure for your users and yourself."
    },
    key: "3"
  },
  {
    toggle: {
      title: "Satisfying Clients Always",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: (
        <>
          A dedicated web development team, combined with strong business acumen
          and project management skills ensure that you get the best experience
          at the end. <strong>navishsol</strong> caters to all kinds of web
          development service, whether you require an online web app development
          for internal usage or your audience and customers. Our highly
          qualified team will take your ideas as their own to give the best of
          results.<br></br>
          Remember, this web development company has already had several
          dealings in this niche since its founding in 2018. Hence, we suggest
          that you let us have a look at your plans and then we will discuss how
          we can serve you better. With <strong>navishsol</strong>, clients not
          only get the designs they want, but also strategy and technology
          consultations for tools like Python, Ruby, PHP, and so on. We are fans
          of collecting great testimonials and always work to earn praises.
        </>
      )
    },
    key: "4"
  }
];

export default webDevData;
