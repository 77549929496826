const data = [
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/On-Demand-Services.png",
    title: "On-Demand Services",
    target: "/industries/on-demand-services",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Healthcare-1.png",
    title: "Healthcare",
    target: "/industries/healthcare",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Ecommerce.png",
    title: "E-Commerce",
    target: "/industries/e-commerce",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Travel-and-Tourisum.png",
    title: "Travel & Tourism",
    target: "/industries/travel-tourism",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Food-and-Grocery.png",
    title: "Food & Groceries",
    target: "/industries/food-groceries",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Finance-1.png",
    title: "Finance",
    target: "/industries/finance",
    classTo: "dropDownSingleItem two"
  },
  {
    img: "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/Education-1.png",
    title: "Education",
    target: "/industries/education",
    classTo: "dropDownSingleItem two"
  }
];

export default data;
