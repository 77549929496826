const data = [
  {
    title: "Education Software Development | Create A Brilliant eLearning App",
    imgSrc:
      "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/education-software-development-elearning-app.png",
    author: "Editor",
    date: "June 22, 2020",
    category: "web-development",
    desc: "The world is changing. When people are looking for a place to shop, they usually open their online platforms to check out products first. Now, when people want to learn something, they are also looking for it online. This is what is called eLearning, or online learning. Believe it or not, online education has become so popular in the past decade that a considerable number of students think about whether going to college or university is even worth it. Especially in America, where an average student is carrying a debt of up to $29,000, online education is becoming hugely popular. Here is a chance where students can skip the rigorous money-crunching factories (educational institutions), and stay at home to learn the same programs at much cheaper rates, sometimes, even for free. This is how technology has added feasibility and innovation to our lives, and all the credit goes to education software development. eLearning has become so prominent that it readily took over the traditional teaching style when the pandemic hit back in March 2020. With education software development companies already working at full strength using the latest technologies & stacks, people are now receiving their lectures online through various channels. Right now, three general websites can be found over at the Internet: online learning platforms, online learning markets, and web conference software.",
    contents: [
      {
        heading: "Classifying Applications In Education Software Development",
        description: "",
        subContents: [
          {
            heading: "Online Learning Websites",
            description:
              "These are the platforms which most of you might have heard of, like Udacity, Coursera and EdX. The platforms serve content to remote users and allow administration at the back to check their weekly progress. The content comes in the form of reading material, video and sometimes even assignments."
          },
          {
            heading: "Online Learning Marketplaces  ",
            description:
              "Another form of educational software development work in eLearning. Here, teachers and students come together to create an education system completely online. Learners rely on the teachers to provide them with regular content and then assess them on their progress through quizzes and games."
          },
          {
            heading: "Web Conferencing Software",
            description:
              "This software is made for students and teachers who would rather prefer a joint session in a virtual classroom like manner. A large number of people can join the conference and interact with a host which is usually the teacher."
          }
        ]
      },
      {
        heading: "Classifyng Applications In Education Software Development",
        description:
          "Since we already have a knack for education software development, perhaps we can show what it means to have a successful eLearning platform:",
        subContents: [
          {
            heading: "Keep Your Competition Close",
            description:
              "Being one of the popular areas of investment, there are several platforms already in place, some of which are doing extremely well. Study what these websites or applications have that differs from your idea. How do they serve their customers and what kind of features do they provide them? This will not only enhance your idea and refine your thought process, but it will also point out the functionality and features that you would like to add."
          },
          {
            heading: "Check Your Target Audience",
            description:
              "Although the education software development aims to target students and teachers, this target alone is simply not enough. Go deeper into details and think about the subjects you are going to offer and how your content can improve their skills? Is your application suitable to meet the demands of growing changes in educational content and update it regularly? What kind of subjects is the audience most interested in? Think about the demographics which includes gender, age, nationality, and even profession."
          },
          {
            heading: "Create A Brilliant Content",
            description:
              "We already have talked about how there are giant eLearning platforms in education software development sector that are already running at their top speed. Before you make a run for a similar level, ask yourself how different is your content from theirs? Sometimes, when the audience is not getting added benefits or incentives, their decisions are based on brand popularity. A top brand usually means that they offer more trust, security and overall benefits. That is why you must set yourself different from your competition either by providing a brilliant educative content or content at a lower price. Here, talk about discount coupons, flash sales or quicker and efficient learning programs. Talk about completion certificates or even reward systems."
          }
        ]
      },
      {
        heading: "Starting Your eLearning Platform",
        description: "Hello",
        subContents: [
          {
            heading: "",
            description: ""
          }
        ]
      }
    ]
  },
  {
    title:
      "Outsourcing Software Development – 5 Mistakes Businesses Should Avoid",
    imgSrc:
      "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/education-software-development-elearning-app.png",
    author: "Editor",
    date: "July 24, 2020",
    category: "education",
    desc: "The world is changing. When people are looking for a place to shop, they usually open their online platforms to check out products first. Now, when people want to learn something, they are also looking for it online. This is what is called eLearning, or online learning. Believe it or not, online education has become so popular in the past decade that a considerable number of students think about whether going to college or university is even worth it. Especially in America, where an average student is carrying a debt of up to $29,000, online education is becoming hugely popular. Here is a chance where students can skip the rigorous money-crunching factories (educational institutions), and stay at home to learn the same programs at much cheaper rates, sometimes, even for free. This is how technology has added feasibility and innovation to our lives, and all the credit goes to education software development. eLearning has become so prominent that it readily took over the traditional teaching style when the pandemic hit back in March 2020. With education software development companies already working at full strength using the latest technologies & stacks, people are now receiving their lectures online through various channels. Right now, three general websites can be found over at the Internet: online learning platforms, online learning markets, and web conference software.",
    contents: [
      {
        heading: "Classifying Applications In Education Software Development",
        description: "",
        subContents: [
          {
            heading: "Online Learning Websites",
            description:
              "These are the platforms which most of you might have heard of, like Udacity, Coursera and EdX. The platforms serve content to remote users and allow administration at the back to check their weekly progress. The content comes in the form of reading material, video and sometimes even assignments."
          },
          {
            heading: "Online Learning Marketplaces  ",
            description:
              "Another form of educational software development work in eLearning. Here, teachers and students come together to create an education system completely online. Learners rely on the teachers to provide them with regular content and then assess them on their progress through quizzes and games."
          },
          {
            heading: "Web Conferencing Software",
            description:
              "This software is made for students and teachers who would rather prefer a joint session in a virtual classroom like manner. A large number of people can join the conference and interact with a host which is usually the teacher."
          }
        ]
      },
      {
        heading: "Classifying Applications In Education Software Development",
        description:
          "Since we already have a knack for education software development, perhaps we can show what it means to have a successful eLearning platform:",
        subContents: [
          {
            heading: "Keep Your Competition Close",
            description:
              "Being one of the popular areas of investment, there are several platforms already in place, some of which are doing extremely well. Study what these websites or applications have that differs from your idea. How do they serve their customers and what kind of features do they provide them? This will not only enhance your idea and refine your thought process, but it will also point out the functionality and features that you would like to add."
          },
          {
            heading: "Check Your Target Audience",
            description:
              "Although the education software development aims to target students and teachers, this target alone is simply not enough. Go deeper into details and think about the subjects you are going to offer and how your content can improve their skills? Is your application suitable to meet the demands of growing changes in educational content and update it regularly? What kind of subjects is the audience most interested in? Think about the demographics which includes gender, age, nationality, and even profession."
          },
          {
            heading: "Create A Brilliant Content",
            description:
              "We already have talked about how there are giant eLearning platforms in education software development sector that are already running at their top speed. Before you make a run for a similar level, ask yourself how different is your content from theirs? Sometimes, when the audience is not getting added benefits or incentives, their decisions are based on brand popularity. A top brand usually means that they offer more trust, security and overall benefits. That is why you must set yourself different from your competition either by providing a brilliant educative content or content at a lower price. Here, talk about discount coupons, flash sales or quicker and efficient learning programs. Talk about completion certificates or even reward systems."
          }
        ]
      },
      {
        heading: "Classifying Applications In Education Software Development",
        description: "",
        subContents: [
          {
            heading: "Starting Your eLearning Platform",
            description:
              "Before you head down to this investment idea, you should realize that eLearning platforms are complex and require a lot of attention. Even if education software development has given us the technology we need to build such an idea, there is constant work that must be put to construct a platform that is helpful for a large community. It should not just be user-friendly; it should have excellent and relevant content as well. Your users should get feedbacks, if they are teachers, or given their assessment or assignment results if they are students. There should be a reward system for students after completing a program, it can be in the form of anything that you like. However, you must take care of what your audience requires. Always start small with education software development and see what feedback you receive. Build up and improve as you go. Since we, at <strong>navishsol</strong>, are a company that has outstanding skills in technologies like Python and Django, we can build complex projects with all the necessary functionality and guidelines related to the front-end. Get in touch and let’s see how we can partner up so you can contribute to the community. Your email ad"
          }
        ]
      }
    ]
  },
  {
    title: "Useful Web Development Tips for Beginner SE Optimizers",
    imgSrc:
      "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/education-software-development-elearning-app.png",
    author: "Editor",
    date: "June 22, 2020",
    category: "education",
    contents: [
      {
        heading: "",
        description: "",
        subContents: [
          {
            heading: "",
            description: ""
          },
          {
            heading: "",
            description: ""
          },
          {
            heading: "",
            description: ""
          }
        ]
      }
    ]
  }
];

export default data;
