import React, { useEffect } from "react";
import SoftDevData from "./SoftDevData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function SoftDev({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="ENTERPRISE SOFTWARE DEVELOPMENT"
        description="Providing a Firm Software Foundation for Businesses"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/erp.png"
        AccordData={SoftDevData}
      />
    </div>
  );
}

export default SoftDev;
