import React, { useEffect } from "react";
import QAData from "./QAData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function QA({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="SOFTWARE QUALITY ASSURANCE"
        description="Ensuring That Your Product Code Is Flawless"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/qa.png"
        AccordData={QAData}
      />
    </div>
  );
}

export default QA;
