import React, { useEffect } from "react";
import style from "./JobsPost.module.css";
// import LinkGen from "../../../Blog/LinkGen";
import Image from "../../../../assets/img/short-logo.png";
import {
  FaLink as LinkIcon,
  FaMapMarkerAlt as Location,
  FaRegCalendarAlt as Calendar
} from "react-icons/fa";
function JobsPost({ data }) {
  useEffect(() => {
    document.title = `${data.title} | navishsol`;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });
  }, [data.title]);
  return (
    <div className={style.mainContainer}>
      <div className={style.wrapper}>
        <div className={style.headingDiv}>
          <h1 className={style.heading}>{data.title}</h1>
          <div className={style.detailDiv}>
            <div
              className={`${style.type} ${data.partTime ? style.partTime : style.fullTime
                }`}
            >
              {data.type}
            </div>
            <div className={style.city}>
              <Location className={style.icon} />
              {data.city}
            </div>

            <div className={style.date}>
              <Calendar className={style.icon} />
              {`Posted on ${data.date}`}
            </div>
          </div>
        </div>
        <div className={style.navishsolCard}>
          <div className={style.imageDiv}>
            <img src={Image} alt="Technology Icon" className={style.fig}></img>
          </div>
          <div className={style.nameSlogan}>
            <h6 className={style.name}>
              <strong>navishsol</strong>
            </h6>
            <span className={style.slogan}>
              Turning your digital dreams into reality
            </span>
          </div>
          <div className={style.webLink}>
            {/* <a href="https://www.navishsol.com">
              <LinkIcon className={style.linkIcon} />
              Website
            </a> */}
          </div>
        </div>
        <div className={style.description}>
          <span className={style.description}>
            {data.description.companyDetail}
          </span>
        </div>
        <ul className={style.listContainer}>
          <span className={style.listHeader}>Responsibilites</span>
          {data.description.responsibilites.map(
            (data, index) => (
              <li key={index} className={style.listItem}>
                {data}
              </li>
            ),
            []
          )}
        </ul>
        <ul className={style.listContainer}>
          <span className={style.listHeader}>Qualifications</span>

          {data?.description?.qualifications?.map(
            (data, index) => (
              <li key={index} className={style.listItem}>
                {data}
              </li>
            ),
            []
          )}
        </ul>
        <ul className={style.listContainer}>
          <span className={style.listHeader}>Skills</span>

          {data?.description?.skills?.map(
            (data, index) => (
              <li key={index} className={style.listItem}>
                {data}
              </li>
            ),
            []
          )}
        </ul>
        {/* <Link to="/become-an-employee">
          <button className={style.btn}>Apply for Job</button>
        </Link> */}
        <ul className={style.listContainer}>
          <span className={style.listHeader}>Instructions to Apply</span>

          <li key={1} className={style.listItem}>
            Send an email with your resume at <strong>hr@navishsol.com</strong>
          </li>
          <li key={2} className={style.listItem}>
            Follow our{" "}
            <a
              href="https://www.linkedin.com/company/90591121"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>{" "}
            page to get updates about new job openings.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default JobsPost;
