const webDevData = [
  {
    toggle: {
      title: "A Unique Shopping Experience",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "This is one platform any size of business can benefit from if just the processes are carried out by the book. Today’s society is highly competitive and focusses on convenience rather than doing things the long way. If a consumer cannot avail quality goods from a place near them, for instance, a supermarket, then that local business is not targeting everyone. The same goes for a shopping store that sells quality products but has placed itself an inconvenient location. With eCommerce website development solutions, everything seems easier. However, there is a catch!"
    },
    key: "0"
  },
  {
    toggle: {
      title: "Increase Reach With Ecommerce Website Development",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "The accessibility of the internet has opened a bigger world in conducting business. Online businesses have proven to be more efficient than traditional ones and <strong>navishsol</strong> understands this. With several years of experience in eCommerce website development, we have given our clients access to entertaining a larger audience, thereby opening a new world of opportunities. Diving into eCommerce web development is a great way to lift your business as more than 4 billion people have access to the Internet now.      "
    },
    key: "1"
  },
  {
    toggle: {
      title: "Scale Your Growth With Us",

      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "An important part of a successful business is to grow it in a way that you increase its products and target audience. However, for online business and eCommerce platforms, such a need should also be in sync with the structure of your online platform. This is why we believe that online businesses should be scalable, hence offering our eCommerce web development solutions. Through this, you can track your progress and enjoy a successful online experience that can let you reach your goals quicker."
    },
    key: "2"
  }
];

export default webDevData;
