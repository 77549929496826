import React from "react";
import style from "./Pillars.module.css";
import Card from "./PillarsCard/Card";
import team from "../../../assets/img/team.png";
import agility from "../../../assets/img/agility.png";
import leadership from "../../../assets/img/leadership.png";
import culture from "../../../assets/img/culture.png";
export default function Pillars() {
  const data = [
    {
      imgSrc: team,
      heading: "Our Team",
      description:
        "Our work-Intensive team craves success and achievement, which defines our positive mindset towards any sort of challenge, whether in outsourcing or software development as a whole."
    },
    {
      imgSrc: agility,
      heading: "Agility",
      description:
        "Our work methodology works like a spearhead towards challenges, enabling us to complete our duties flawlessly, without any delays."
    },
    {
      imgSrc: leadership,
      heading: "Leadership",
      description:
        "Leaders lead by example and so do ours who have achieved their rightful place through years’ of experience and in-depth know-how of today’s outsourcing software development trends."
    },
    {
      imgSrc: culture,
      heading: "Culture",
      description:
        "We embrace the idea of meeting people with different knowledge and background because we are a pool that promotes innovation by leveraging emerging tools and technology."
    }
  ];
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.headingDiv}>
          <p className={style.befHeading}>Our Core Beliefs</p>
          <h3 className={style.headingText}>
            The Four Pillars of <strong>navishsol</strong>
          </h3>
        </div>
        <div className={style.pillarsDiv}>
          {data.map((data, index) => (
            <Card
              key={index}
              imgSrc={data.imgSrc}
              heading={data.heading}
              description={data.description}
            ></Card>
          ))}
        </div>
      </div>
    </div>
  );
}
