import React, { useEffect } from "react";
import SupportData from "./SupportData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function Support({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="PRODUCT SUPPORT SERVICES"
        description="Endless Support Even After We Have Delivered"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/suport.png"
        AccordData={SupportData}
      />
    </div>
  );
}

export default Support;
