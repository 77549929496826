const jobsData = [
  {
    title: "Front-end React Developer",
    url: "frr2ht3cs3sa2",
    city: "Remote",
    freelance: true,
    fullTime: true,
    internship: false,
    partTime: false,
    temporary: false,
    type: "Full Time",
    date: "March 19,2022",
    imgSrc: "",
    description: {
      companyDetail:
        "navishsol is a growing company in Lahore that offers a combination of consulting, outsourcing and specialized services to global clientele across all types of web and mobile app development.The company was launched by experienced and visionary IT professionals with more than 10 years of industry experience in the fraternity of software development. navishsol aspires to automate the daily work processes of clients with innovative business solutions with a vision to produce efficient and time-saving results.We take pride in our diversified team that consist people from both domestic and international backgrounds working together to strive excellence.While we have attained several milestones in very less time, it is just a warmup for us! If you think you have the technical skills to contribute in our fast-growing business, feel free to apply!",
      responsibilites: [
        "Gather and refine specifications and requirements based on technical requirements or UX design",
        "Ability to convert Figma designs into fully responsive web pages actively using native CSS and SCSS",
        "Make sure the site’s design is Responsive, adaptable and strike a balance between useful and aesthetically pleasing design",
        "Understanding of RESTful APIs and how they work with web applications",
        "Work with back-end developers to integrate UI components with APIs and databases",
        "Debug errors, troubleshoot issues, and perform routine performance optimizations",
        "Being able to write Search engine optimized code.",
        "Write high-quality, scalable, and reusable code",
        "Stay plugged into emerging technologies and industry trends"
      ],
      qualifications: [
        "Minimum 18 months of experience  with JavaScript and React.js development, consuming REST and/or GraphQL APIs",
        "Experience in Semantic HTML, CSS, SCSS styling and Typescript",
        "Worked with Redux, and React Hooks",
        "Familiarity with the GraphQL/Mongodb/Postgres",
        "Basic knowledge of Git",
        "Hands on experience on Next JS (optional)"
      ],
      skills: [
        "HTML, CSS3, Sass",
        "JavaScript",
        "React",
        "NextJs (Additional)",
        "Redux",
        "Git"
      ]
    }
  },
  {
    title: "Nodejs Developer",
    url: "bano2ex2ts2mo2",
    city: "Remote",
    freelance: true,
    fullTime: true,
    internship: false,
    partTime: false,
    temporary: false,
    type: "Full Time",
    date: "March 19,2022",
    description: {
      companyDetail:
        "navishsol is a growing company in Lahore that offers a combination of consulting, outsourcing and specialized services to global clientele across all types of web and mobile app development.The company was launched by experienced and visionary IT professionals with more than 10 years of industry experience in the fraternity of software development. navishsol aspires to automate the daily work processes of clients with innovative business solutions with a vision to produce efficient and time-saving results.We take pride in our diversified team that consist people from both domestic and international backgrounds working together to strive excellence.While we have attained several milestones in very less time, it is just a warmup for us! If you think you have the technical skills to contribute in our fast-growing business, feel free to apply!",
      responsibilites: [
        "Integration of user - facing elements developed by front - end developers with server - side logic.",
        "Writing reusable, testable, and efficient code",
        "Design and implementation of low-latency, high-availability, and performance applications",
        "Implementation of security and data protection methodologies",
        "Integration of data storage solutions such as NoSQL DB",
        "Work in an Agile Scrum framework such as JIRA",
        "Create and maintain server configurations",
        "Resolving bugs and performance issues",
        "Develop new functionality according to requirements",
        "Introducing / Improving architectures of the platforms",
        "Designing customer-facing UI and back-end services for various business processes.",
        "Developing high-performance applications by writing testable, reusable, and efficient code.",
        "Implementing effective security protocols, data protection measures, and storage solutions.",
        "Documenting Node.js processes, including database schemas, as well as preparing reports.",
        "Recommending and implementing improvements to processes and technologies.",
        "Keeping informed of advancements in the field of Node.js development."
      ],
      qualifications: [
        "1.5+ years of experience",
        "Experience working as part of an Agile team",
        "Advanced understanding of web architecture and database fundamentals",
        "Demonstrate the ability to work independently and as part of a development team with a range of scientific and professional backgrounds",
        "Understanding of object-oriented design, data structures, and algorithms",
        "Expertise in JavaScript, Node.js",
        "Experience with creating APIs for third parties and consuming third-party APIs will be a plus",
        "Expertise in AWS or similar cloud hosting environment",
        "Experience integrating with external systems will be a plus",
        "Node 8.0 with promises, async/await, generators, and coroutines"
      ],
      skills: [
        "Javascript, Typescript",
        "Nodejs, Expres",
        "Socketio",
        "WebRTC",
        "Database management : Mongodb, Postgres, graphql",
        "Git"
      ]
    }
  }
];

export default jobsData;
