const data = [
  {
    toggle: {
      title: "Software Quality Assurance",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Work does not end when we deliver what you want. Our product support services ensure that you keep on doing what you do best and let us take care of the rest"
    },
    key: "0"
  },
  {
    toggle: {
      title: "Thorough Maintenance",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "Oftentimes a company has two options: either they can hire an in-house maintenance team or choose to outsource the job. At <strong>navishsol</strong>, we provide an opportunity where the in-house team can focus on a more important task while we take care of the rest. Our dedicated software maintenance team takes care of everything from installation, compatibility with third-party, upgrade and improvements."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Support Service Up To 3rd Degree",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "Our team handles your product as if they are our own. We put the effort in getting to know client’s software, how their team and organization works so that we can provide the best support services that they might need."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Analytics & Reporting",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "With <strong>navishsol</strong>, get a complete analysis of software and enabling clients to find out if they are achieving their true goals as per what they believed. Reporting makes it easier for all business stakeholders to understand what needs to be done and what further steps should be taken."
    },
    key: "3"
  }
];

export default data;
