import React, { useEffect } from "react";
import Main from "../../Main/Main";
import style from "./JobApply.module.css";
import Form from "./Form";
function JobApply({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="BECOME AN EMPLOYEE"
        description="Want to be a part of us? We’d love to hear from you"
      />
      <div className={style.mainFormContanier}>
        <div className={style.formDetailsContainer}>
          <div className={style.mainContainerImg}>
            <div className={style.innerContainerImg}>
              <img
                alt=""
                src="https://navishsol.com/wp-content/uploads/2020/05/apply-job.svg"
                className={style.figEmployee}
              />
            </div>
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default JobApply;
