import React from "react";
const data = [
  {
    toggle: {
      title: "What Is DevOps?",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: (
        <>
          DevOps development is a set of practices that bridge and automate the
          work happening between the development team and the IT operations
          teams. Now, they can communicate better and work together more
          efficiently to test and release software quickly and unfailingly.
          DEVelopment and OPerations teams must be in sync if efficiency is
          required, hence the term DevOps software development.<br></br> At
          <strong>navishsol</strong>, DevOps is more than just a method of work,
          it’s a culture. Our emphasis towards shifting mindset for better team
          collaborations and integrations has led us to the treasure which is
          DevOps development. Now, not only do we deliver at a faster pace, but
          we also deliver higher value to businesses and clients.
        </>
      )
    },
    key: "0"
  },
  {
    toggle: {
      title: "Agile DevOps Development",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "Our belief in Agile methodology ensures that you get your product sooner with the best experience. With our DevOps development services, you can expect incremental updates to arrive consistently, improving your product operations and its quality. In general, Agile development is a wider term for several incremental and iterative methodologies. From using Kanban to Scrum to Lean and Extreme, <strong>navishsol</strong> will streamline your software development by adding a touch of DevOps to these methods. We will take care of your development changes from building to deployment, a journey you will never forget."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Continuous Delivery With DevOps",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: (
        <>
          Within our DevOps development procedures, we exercise continuous
          delivery. Here, <strong>navishsol</strong> works to provide you with new
          features and configuration changes which adds to your product’s
          functionality. As you bring out new challenges for your business, we
          work to make it possible for you to complete them. <br></br>DevOps has
          given us the power to effectively utilize skills in development,
          testing and deployment. Instead of deploying large data after a large
          time, we work to deploy short ones continuously. We commit small
          incremental batches, sending them to the operations so that the
          delivery pipeline always keeps flowing. As a result, we bring your
          products in a very safe and auditable manner, what you deserve!
        </>
      )
    },
    key: "2"
  },
  {
    toggle: {
      title: "Assessment, Automation and Management",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: (
        <>
          Through <strong>navishsol</strong>, get the all-rounder experience by our
          DevOps software development where we analyse target key points and
          more. We perform assessments to mitigate risks, increase productivity
          and assist your core team to produce an absolute integration of
          delivery pipeline automation cycle. Via the use of automation
          practices in DevOps development, we eliminate the need to focus on
          mundane, repetitive and manual tasks. We are already sending all codes
          to a gauntlet of automated tests with continuous delivery. Now, we
          also package and send them forward to the production for an automated
          deployment as well.<br></br> <strong>navishsol</strong> aims to create
          composable and modular applications because we know they are
          manageable and reliable. Through our processes, we are effectively
          breaking the wall standing between operations and development teams
        </>
      )
    },
    key: "3"
  },
  {
    toggle: {
      title: "Early Risk Detection",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "Our engineers stay alert throughout the DevOps software development process. Since DevOps development makes use of shifting left in the CI/CD, we will detect early the signs of bugs and disturbances. This proactive approach lets us outshine those who rely on reactive testing. It is interesting to note that early identification of risks means that we reduce defects by up to 35%."
    },
    key: "4"
  }
];

export default data;
