import React, { useEffect } from "react";
import DevOpsData from "./DevOpsData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function DevOps({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="DEVOPS"
        description="Let Our Engineers Define The Best Strategy For You!"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/devops.png"
        AccordData={DevOpsData}
      />
    </div>
  );
}

export default DevOps;
