import React from "react";
import Ecom from "../InnerComponent/ECom/TabsEcomData";
import Education from "../InnerComponent/Education/TabsEducationData";
import Finance from "../InnerComponent/Finance/TabsFinanceData";
import Food from "../InnerComponent/Food/TabsFoodData";
import HealthCare from "../InnerComponent/HealthCare/TabsHealthData";
import OnDemand from "../InnerComponent/OnDemand/TabsDemandData";
import Travel from "../InnerComponent/Travel/TabsTravelData";
import TempComponent from "../../Services/InnerComponents/TempComponent/TempComponents";
const content = [
  {
    name: "Service On-Demand",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/service-on-demand.png"
        AccordData={OnDemand}
        backgroundClass="white"
      />
    )
  },
  {
    name: "Healtcare",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/healthcare.png"
        AccordData={HealthCare}
        backgroundClass="white"
      />
    )
  },
  {
    name: "E-Commerce",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/ecom.png"
        AccordData={Ecom}
        backgroundClass="white"
      />
    )
  },
  {
    name: "Travel & Tourism",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/travel.png"
        AccordData={Travel}
        backgroundClass="white"
      />
    )
  },
  {
    name: "Food & Groceries",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/food.png"
        AccordData={Food}
        backgroundClass="white"
      />
    )
  },
  {
    name: "Finance",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/fintech.png"
        AccordData={Finance}
        backgroundClass="white"
      />
    )
  },
  {
    name: "Education",
    data: (
      <TempComponent
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/education.png"
        AccordData={Education}
        backgroundClass="white"
      />
    )
  }
];

export default content;
