import React from "react";
import style from "./Expertise.module.css";
import "./Expertise.css";
import Tabs from "./Tabs/Tabs";
function Expertise() {
  return (
    <div className={style.mainContainer}>
      <div className={style.headingContainer}>
        <h1 className={style.heading}>
          <strong>INDUSTRY</strong> EXPERTISE
        </h1>
        <p className={style.description}>
          Through outsourcing software development, <strong>navishsol</strong>{" "}
          serves numerous industrial sectors.<br></br>
          We serve businesses of all background and offer a complete range of
          innovative web development solutions.
        </p>
      </div>
      <Tabs />
    </div>
  );
}

export default Expertise;
