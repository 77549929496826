import React, { useEffect } from "react";
import UIUXData from "./UIUXData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function UIUX({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="Web Development"
        description="Making Sure Your Business Reaches New Heights"
      />
      <AccordComp
        imgSrc="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/uiux.png"
        AccordData={UIUXData}
      />
    </div>
  );
}

export default UIUX;
