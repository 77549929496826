import React from "react";
const webDevData = [
  {
    toggle: {
      title: "Efficiently Connecting Buyers And Sellers",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "The service on-demand industry is becoming a bigger need of today’s market with every passing day. A platform where buyers can connect with their sellers and give them exactly what they demand is developing into a trend more and more. These services can target any areas, transportation, food, repair & maintenance, and clothing. One big example here is Uber, which owns no vehicles, but because it connects drivers with their riders, it generated more than USD 3 billion in revenues."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Services On-Demand Industry – A New Economy",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "While Uber and other similar companies like Airbnb are not the only examples, they are some of the top services in the field of on-demand services. New business models are working in such a way that they take a more minimal approach towards, otherwise, typical business setups. The advancement in technology has enabled businesses to build better methods of reaching out to their potential customers. However, only those who integrate a smooth operation and provide a quality platform can outshine in this industry."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Personalized Services",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: (
        <>
          Consumers now demand speed of delivery as they do not want to wait in
          a queue or be put on hold. Companies which are venturing into this
          economy have a clear edge if they choose a model which is fresh, and
          provides exactly what the people want. The quicker you adapt to the
          idea about a changing world of business, the better your business can
          progress.<br></br>
          <br></br> At <strong>navishsol</strong>, we can customize your on-demand
          service application so that it contains real-time order tracking for
          your end consumers. This way, customers can place an order through
          your app and know the real-time status of their order. Your clients,
          in turn, can track the progress of their work, payment method and
          delivery status as well. Now, both parties can monitor order status so
          that there is no room for miscommunication.
        </>
      )
    },
    key: "2"
  }
];
export default webDevData;
