const data = [
  {
    p1: {
      heading: "Better Operations Support",
      description:
        "Shifting to our advanced healthcare software development solutions mean that you will be establishing a cleaner and more organized system of operations. Setting operations online will simplify your business as we focus on fine-tuning your app landscape to your IT and business objectives. This includes providing features of enhanced patient care and better coordination and engagement. From assessment, transition, and execution, we will consider everything."
    },
    p2: {
      heading: "A Digital Healthcare System Is the Look-Out Trend",
      description:
        "We specialize in building and implementing growth models using the latest healthcare software development methodologies. <strong>navishsol</strong> understands the need for the market in today’s world. Having built platforms for many businesses through software development for healthcare, it is safe to assume that a large portion of start-ups is focussing on this sector. The fact that a digital application can collect, analyse and sort data a lot quicker and more efficiently than manual procedures lets you focus on other, and bigger, aspects of the combination of healthcare and technology. "
    },
    imgSrc:
      "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/healthcare2-1.png"
  }
];

export default data;
