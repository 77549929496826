import React from "react";
import Points from "./Points/Points";
import EngageModels from "./EngagModels/EngageModel";
import { Route, Switch } from "react-router-dom";
import * as displays from "./ServiceCompImport";
export default function Services() {
  return (
    <div>
      <Switch>
        <Route path="/services/" exact>
          <displays.WebDev title="Web Development | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/web-development">
          <displays.WebDev title="Web Development | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/mob-application">
          <displays.MobileApp title="Mobile Application | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/devops">
          <displays.Devops title="DevOps | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/qa">
          <displays.QA title="Software Quality Assurance | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/data-scrapping">
          <displays.DataScrapping title="Data Scraping | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/data-science">
          <displays.DataScience title="Big Data & Data Science | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/iot">
          <displays.IOT title="Cryptography & IOT | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/support">
          <displays.Support title="Product Support Services | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/ui-ux">
          <displays.UIUX title="Branding & UI/UX Designing | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/software-development">
          <displays.SoftDev title="Enterprise Software Developmen | <strong>navishsol</strong>" />
        </Route>
        <Route path="/services/product-management">
          <displays.ProManagement title="Product Management | <strong>navishsol</strong>" />
        </Route>
      </Switch>
      <Points />
      <EngageModels />
    </div>
  );
}
