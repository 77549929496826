import React, { useState } from "react";
import style from "./contact.module.css";
import { ToastContainer } from "react-toastify";
import sendEmail from "../../../api-calls/send-email";
import loading from "../../../assets/img/spinner.svg";
export default function Contact() {
  const initState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    isContact: false
  };
  const [userData, setuserData] = useState(initState);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const onSubmit = e => {
    setButtonDisabled(true);
    e.preventDefault();

    if (userData.name === "" || userData.email === "") return;

    sendEmail({
      ...userData,
      setButton: setButtonDisabled,
      initState,
      setData: setuserData
    });
  };
  return (
    <div className={style.mainCard}>
      <ToastContainer />
      <div className={style.innerCard}>
        <div className={style.text}>
          <p className={style.normal}>Have an idea to discuss?</p>
          <p className={style.big}>
            <strong>Schedule</strong> a free <strong>30</strong> mins call
          </p>
        </div>
        <div className={style.form}>
          <form onSubmit={onSubmit}>
            <input
              className={style.input}
              value={userData.name}
              type="text"
              placeholder="Name"
              onChange={e =>
                setuserData({
                  ...userData,
                  name: e.target.value
                })
              }
              required
            ></input>
            <input
              className={style.input}
              value={userData.email}
              type="email"
              onChange={e =>
                setuserData({
                  ...userData,
                  email: e.target.value
                })
              }
              required
              placeholder="Email"
            ></input>
            <button
              className={`${style.btn} ${buttonDisabled && style.disabled}`}
              type="submit"
              disabled={buttonDisabled}
            >
              {/* <img
                src="https://<strong>navishsol</strong>.com/wp-content/uploads/2020/04/paper-arrow.svg"
                alt="Arrow"
                className={style.btnArrow}
              ></img> */}
              {buttonDisabled ? (
                <img src={loading} width="40px" height="auto" />
              ) : (
                "Schedule"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
