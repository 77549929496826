import Services from "../../../../../assets/img/services.png";
import Education from "../../../../../assets/img/education.png";
import Health from "../../../../../assets/img/health.png";
import ECommerce from "../../../../../assets/img/ecommerce.png";
import Finance from "../../../../../assets/img/finance.png";
import Foods from "../../../../../assets/img/foods.png";
import Travel from "../../../../../assets/img/travel.png";

import ServicesI from "../../../../../assets/img/servicesI.svg";
import EducationI from "../../../../../assets/img/educationI.svg";
import HealthI from "../../../../../assets/img/healthI.svg";
import ECommerceI from "../../../../../assets/img/ecommerceI.svg";
import FinanceI from "../../../../../assets/img/financeI.svg";
import FoodsI from "../../../../../assets/img/foodsI.svg";
import TravelI from "../../../../../assets/img/travelI.svg";

const data = [
  {
    listContent: {
      title: "Services on-Demand",
      imgSrc: Services
    },
    tabContent: {
      p1: {
        heading: "Efficiently Connecting Buyers & Sellers",
        description:
          "The service on-demand industry is becoming a bigger need of today’s market with every passing day. A platform where buyers can connect with their sellers and give them exactly what they demand is developing increasingly into a trend. These services can target any areas, transportation, food, repair & maintenance, and clothing."
      },

      p2: {
        heading: " Services On-Demand Industry",
        description:
          "New business models are working in such a way that they take a more minimal approach towards, otherwise, typical business setups."
      },

      target: "",
      imgSrc: ServicesI
    },
    activeKey: "first"
  },
  {
    listContent: {
      title: "Healthcare",
      imgSrc: Health
    },
    tabContent: {
      p1: {
        heading: "Reshaping Healthcare With Custom Software Development",
        description:
          "Keeping up with a digitally transforming healthcare industry should not be overwhelming when you have the right people guiding you. It is important that you decide which technology is worth your investment, regardless if it is monetary or time. One thing to keep in mind here is that successfully shifting to such an industry requires innovative ideas and thinking. We understand the importance of what the U.S’s HIPAA delivers."
      },

      p2: {
        heading: "Shift to Modern Technology",
        description:
          "The first step towards modernism is to cut the practice of traditional paper-based activities and record them digitally."
      },

      target: "",
      imgSrc: HealthI
    },
    activeKey: "second"
  },
  {
    listContent: {
      title: "E-Commerce",
      imgSrc: ECommerce
    },
    tabContent: {
      p1: {
        heading: "E-commerce",
        description:
          "This is one platform any size of business can benefit from if they do things the right way. Today’s society is highly competitive and focusses on convenience rather than doing things the long way. If a consumer can avail quality goods from a place near them, for instance, your supermarket is not targeting everyone."
      },

      p2: {
        heading: "Adding Convenience to Your Business",
        description:
          "Our belief in building fast and reliable products for clients has helped transform the E-commerce industry where buyers and sellers interact in a much convenient manner."
      },

      target: "",
      imgSrc: ECommerceI
    },
    activeKey: "third"
  },
  {
    listContent: {
      title: "Travel & Tourism",
      imgSrc: Travel
    },
    tabContent: {
      p1: {
        heading: "Travel & Tourism",
        description:
          "With navishsol, there is no need to look further in order to upscale your booming travel and tourism business. Our exciting travel business solutions empower your business with technical consultancy, customer portals and a back-office management system. We strive to bridge the gap between travel companies and their customers so that customers can explore freely, and the companies can enjoy bigger opportunities."
      },

      p2: {
        heading: "Travel Tech Engineering Experience",
        description:
          "Our work with different travel and hospitality businesses around the globe have given us necessary knowledge about travel management solutions."
      },

      target: "",
      imgSrc: TravelI
    },
    activeKey: "fourth"
  },
  {
    listContent: {
      title: "Food & Groceries",
      imgSrc: Foods
    },
    tabContent: {
      p1: {
        heading: "Food & Groceries",
        description:
          "Food and grocery business doesn’t have to stick to the way it has been in the past. Modern solutions state the need for software that redefines how groceries are bought, sold and business operations are done. With navishsol you are reevaluating your potential for online business in this sector."
      },

      p2: {
        heading: "E-Commerce Software for Groceries",
        description:
          "Our custom software E-commerce solutions specially designed for grocery and retail sector includes mobile applications of the current stock."
      },

      target: "",
      imgSrc: FoodsI
    },
    activeKey: "fifth"
  },
  {
    listContent: {
      title: "Finance",
      imgSrc: Finance
    },
    tabContent: {
      p1: {
        heading: "Finance",
        description:
          "At navishsol, we work to create a solution for banks, exchanges and brokers to provide them with a saving on their online trading platforms, online business infrastructure and overall systems. We work to reduce cost and risk by providing experience and resource for proprietary platforms."
      },

      p2: {
        heading: "We Provide Development Possibilities",
        description:
          "navishsol hosts teams of developers that have expertise in the finance industry, developing and designing accounting software with unparalleled APIs"
      },

      target: "",
      imgSrc: FinanceI
    },
    activeKey: "sixth"
  },
  {
    listContent: {
      title: "Education",
      imgSrc: Education
    },
    tabContent: {
      p1: {
        heading: "Education",
        description:
          "navishsol has worked on some amazing and out-of-box educational software to create solutions to distant learning. Today’s education and e-learning industry aims to reduce the distance between a person and their education. We, at navishsol, believe in this cause and have already punched in our experience to make sure that we stay true to our goals, which is innovation."
      },

      p2: {
        heading: "A Brilliant LMS",
        description:
          "navishsol develops unique Learning Management Systems that cater to the needs of general students through an excellent User Interface (UI)."
      },

      target: "",
      imgSrc: EducationI
    },
    activeKey: "seventh"
  }
];

export default data;
