import React, { useEffect } from "react";
// import style from "./Careers.module.css";
import Main from "../Main/Main";
import LifeAtNAVISHSOL from "./LifeAtNAVISHSOL/Life";
import Perks from "./Perks/Perks";
import JobsPanel from "./JobsPanel/JobsPanel";
function Careers({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="CAREERS"
        description="Explore opportunities to take your career to the next level"
      ></Main>
      <LifeAtNAVISHSOL />
      <Perks />
      <JobsPanel />
    </div>
  );
}

export default Careers;
