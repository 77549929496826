import React, { useEffect } from "react";
import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/About/About";
import Industries from "./Components/Industries/Industries";
import Service from "./Components/Services/Services";
import Blog from "./Components/Blog/Blog";
import Careers from "./Components/Careers/Careers";
import Contact from "./Components/Contact/Contact";
import JobsDetail from "./Components/Careers/JobsPanel/JobsPost/JobsDetail";
import Footer from "./Components/Footer/main";
import SearchPage from "./Components/Blog/BlogSearch/SearchPage";
import BecomEmployee from "./Components/Careers/JobApply/JobApply";
import InvalidRoute from "./Components/404/404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Switch>
          <Route path="/" exact>
            <Home
              title={`navishsol - Turning your digital dreams into reality.
            `}
            ></Home>
          </Route>
          <Route path="/about" exact>
            <AboutUs title="About Us - navishsol" />
          </Route>
          <Route path="/contact-us" exact>
            <Contact title="Contact Us - navishsol" />
          </Route>
          <Route path="/industries">
            <Industries />
          </Route>
          <Route path="/services">
            <Service />
          </Route>
          <Route
            path="/blog"
            render={props => <Blog {...props} title={`Blog | navishsol`} />}
          ></Route>

          <Route path="/search/:id" exact component={SearchPage} />
          <Route path="/careers" exact>
            <Careers title="Careers - navishsol" />
          </Route>
          <Route path="/job/:title" exact component={JobsDetail} />
          <Route path="/become-an-employee">
            <BecomEmployee title="Become an Employee | navishsol" />
          </Route>
          <Route component={InvalidRoute} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
