import React from "react";
const webDevData = [
  {
    toggle: {
      title: "Reshaping Healthcare For The Better",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Keeping up with a digitally transforming healthcare industry should not be overwhelming when you have the right people for guidance. With <strong>navishsol</strong>’s software development for healthcare, you can decide which technology is worth your investment, regardless if it is financial or time. One thing to keep in mind here is that successfully shifting to such an industry requires innovative ideas and thinking. Here, the main goal is streamlining physicians’ work and lowering the cost to patients by building a fantastic online presence."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Shift to Modern Technology",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "The first step towards modernism is to cut the practice of traditional paper-based activities and record them digitally. Patient management systems like these are essential when developing a strategic platform where physicians can communicate with patients. Such solutions will not only help these two parties but also aid the healthcare administration to manage their schedules better. While it is a good idea to aim for stand-alone software development for healthcare that stores data locally, it is not feasible. As healthcare software development expands, so does the security solutions that empower the company to firmly protect patients’ privacy. That is what <strong>navishsol</strong> aims for: a shift towards modern tools in this business."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Personalized Services",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: (
        <>
          Consumers now demand speed of delivery as they do not want to wait in
          a queue or be put on hold. Companies which are venturing into this
          economy have a clear edge if they choose a model which is fresh, and
          provides exactly what the people want. The quicker you adapt to the
          idea about a changing world of business, the better your business can
          progress.<br></br>
          <br></br> At <strong>navishsol</strong>, we can customize your on-demand
          service application so that it contains real-time order tracking for
          your end consumers. This way, customers can place an order through
          your app and know the real-time status of their order. Your clients,
          in turn, can track the progress of their work, payment method and
          delivery status as well. Now, both parties can monitor order status so
          that there is no room for miscommunication.
        </>
      )
    },
    key: "2"
  }
];

export default webDevData;
