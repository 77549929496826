const data = [
  {
    toggle: {
      title: "Big Data & Data Science",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Multiple streams of structured and unstructured data won’t get organized unless you hire professionals who are experts in analysing data and identifying key metrics."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Visualization of Big Data",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "All forms of structured and unstructured data are meaningless unless proper algorithms are placed that organize, sort and pick out the data needed. <strong>navishsol</strong> helps to create a visualization of such data so that it can be better understood and used to create an insight that matters."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Process Natural Language",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "With our data science techniques, we tend to bridge the gap between a machine and its human user. Natural language processing is the key where we enrich the machine learning experience and build a human-like environment for conversation and command input."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Grow Business with Intelligence",
      imgBef:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>navishsol</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "<strong>navishsol</strong>’s collection of data not only sorts it out but also create a realistic and accurate insight into an online business situation. Using real-time reports, dashboards and data science, we create a better understanding of businesses."
    },
    key: "3"
  }
];

export default data;
